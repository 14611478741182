import React from "react";
import Layout from "../components/layout";
import styled from "@emotion/styled";
import servicesHeaderImage from "../assets/images/services-header.jpg";
import activitiesSidebar01 from "../assets/images/activities-pic-01.jpg";
import activitiesSidebar03 from "../assets/images/activities-pic-03.jpg";

const PageHeader = styled.div`
  background: url(${servicesHeaderImage});
  background-size: cover;
  background-position: center center;
  color: black;
  padding: 20px;
  padding-left: 0;
  min-height: 250px;
  border-bottom: solid 2px black;
  margin-bottom: 30px;
`;

export default () => {
  return (
    <Layout>
      <PageHeader>
        <h1>
          <span className="animated fadeInLeft">Key Services</span>
        </h1>
      </PageHeader>
      <div
        className="sub-page main-content"
        style={{
          minHeight: "300px",
          display: "grid",
          gridTemplateColumns: "3fr 1fr",
          gridGap: "3rem",
        }}
      >
        <div>
          <p>
            We place utmost emphasis on patient happiness and well-being. Our
            activities programme ensures a wide breadth of interests are catered
            for.
          </p>
          <ul
            style={{
              paddingBottom: "20px",
              marginBottom: "20px",
              borderBottom: "dashed 1px #666",
            }}
          >
            <li>24 hour nursing care</li>
            <li>Dietician</li>
            <li>GP services</li>
            <li>Speech therapy</li>
            <li>'Fit for Life' exercise programme</li>
            <li>Twice weekly physiotherapist visits</li>
            <li>Chiropody</li>
            <li>'Pamper room'</li>
            <li>Gardening in central courtyard</li>
            <li>Day trips</li>
            <li>Games coordinator</li>
          </ul>

          <h4>HIQA Registered for 33 residents</h4>
          <p>
            The nursing home is registered and approved with the Health
            Information and Quality Authority.
          </p>
          <p
            style={{
              paddingBottom: "20px",
              marginBottom: "20px",
              borderBottom: "dashed 1px #666",
            }}
          >
            Deerpark can accommodate adults over the age of 18 with physical,
            psychiatric and learning disabilities, and all age onset conditions.
          </p>

          <h4>Types of Care</h4>
          <ul>
            <li>Short term respite care</li>
            <li>Long term</li>
            <li>Private and 'Fair Deal'</li>
          </ul>
        </div>
        <div className="sidebar">
          <img src={activitiesSidebar01} alt="" />
          <img src={activitiesSidebar03} alt="" />
        </div>
      </div>
    </Layout>
  );
};
